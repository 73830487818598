import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import '../styles/Announcement.css';
import '../styles/Loading.css';
import AnnouncementService from '../services/AnnouncementService';
import HostAndPortInfo from "../enums/EnHostandPortInfo";


const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function Announcement() {

  const dropdownRef = useRef(null);
  const announcementService = new AnnouncementService()
  const [loading, setLoading] = useState(false); // Yüklenme durumu

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('Tarih');
  const [announcements, setAnnouncements] = useState([]);
  const [announcementsDates, setAnnouncementsDates] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const pdfLinks = {
    10: { url: '/pdf-files/AZYT 2025 1. ÖN ELEME SONUÇLARI.pdf', text: '1. Ön Eleme Sonuçlarını indirmek için tıklayınız.' },
    11: { url: '/pdf-files/AZTY 2025 ÖN ELEME REVİZE 271124.pdf', text: 'Revize Edilmiş Ön Eleme Sonuçlarını indirmek için tıklayınız.' }
  };




  //herhangi boş yere tıklandığında dropdown kapansın
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setDropdownOpen(false); // Tarih seçildikten sonra dropdown kapanır
  };

  useEffect(() => {

    setLoading(true)

    announcementService.getAllAnnouncement().then(result => {

      if (result.data.success) {


        console.log("result")
        console.log(result)

        // Tüm veriyi setAnnouncements ile set et
        setAnnouncements(result.data.data);

        // createDate değerlerini alıp setAnnouncementsDates'e at
        const createDates = result.data.data.map(announcement => announcement.createDate);
        setAnnouncementsDates(createDates); // Sadece createDate'leri ayarla
        setLoading(false)

      }

    }).catch(error => {
      toast.error(error.response.data.data.message);
      setLoading(false)

    })



  }, [])


  useEffect(() => {

    setCurrentPage(1); // ilk sayfaya gotur
    setLoading(true)

    //eger tarih seciliyse tarihe gore getir
    if (selectedDate !== "Tarih" && selectedDate !== "Tümü") {

      announcementService.getAllAnnouncementByDate(selectedDate).then(result => {

        if (result.data.success) {

          setAnnouncements(result.data.data)
          setLoading(false)

        }

      }).catch(error => {
        toast.error(error.response.data.data.message);
        setLoading(false)

      })

    } else if (selectedDate === "Tümü") {

      //tarih tümü secili ise tumunu getir
      announcementService.getAllAnnouncement().then(result => {

        if (result.data.success) {


          console.log("result")
          console.log(result)

          // Tüm veriyi setAnnouncements ile set et
          setAnnouncements(result.data.data);

          // createDate değerlerini alıp setAnnouncementsDates'e at
          const createDates = result.data.data.map(announcement => announcement.createDate);
          setAnnouncementsDates(createDates); // Sadece createDate'leri ayarla
          setLoading(false)

        }

      }).catch(error => {
        toast.error(error.response.data.data.message);
        setLoading(false)

      })

    }

  }, [selectedDate])

  const formatDate = (dateString) => {
    const monthNames = { 1: "Oca", 2: "Şub", 3: "Mar", 4: "Nis", 5: "May", 6: "Haz", 7: "Tem", 8: "Ağu", 9: "Eyl", 10: "Eki", 11: "Kas", 12: "Ara" };

    const [year, month, dayWithTime] = dateString.split("-");
    const [day] = dayWithTime.split("T"); // Saat bilgisini ayırmak için

    // Ayı sayısal değere çevir ve monthNames içerisinden ayın adını al
    const monthName = monthNames[parseInt(month, 10)];


    return { month: monthName, day };
  };

  const formatDateDropdown = (dateString) => {

    const [year, month, dayWithTime] = dateString.split("-");
    const [day] = dayWithTime.split("T"); // Saat bilgisini ayırmak için

    const formatedDay = year + "-" + month + "-" + day

    return { formatedDay };
  };


  // sayfalama için
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = announcements.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(announcements.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    console.log("pageNumber", pageNumber)
    setCurrentPage(pageNumber);

  }


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // duyuruları görüntüleyen modal için
  const handleCardClick = (announcement) => {
    setSelectedAnnouncement(announcement);
  };

  const handleCloseModal = () => {
    setSelectedAnnouncement(null);
  };


  return (

    <div className="announcement-container ">


      <div className="first" >
        <p className="first-text">Duyurular</p>
      </div>


      <div className="my-container-announcement">

        <div className="dropdown" onClick={toggleDropdown}>
          <p className="dropdown-text">
            {selectedDate}
            <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
          </p>
          {isDropdownOpen && (
            <div ref={dropdownRef} className="dropdown-content">
              {/* Tümünü seçeneğini ilk sıraya ekliyoruz */}
              <p key="all" onClick={() => handleDateSelect("Tümü")}>Tümü</p>

              {/* Aynı tarihleri önlemek için benzersiz bir Set kullanıyoruz */}
              {[...new Set(announcementsDates.map(date => formatDateDropdown(date).formatedDay))].map((uniqueDate) => (
                <p key={uniqueDate} onClick={() => handleDateSelect(uniqueDate)}>{uniqueDate}</p>
              ))}
            </div>
          )}
        </div>

        {loading === true &&
          <div className="loading-container">
            <div className="loading-icon"></div> {/* Loading ikonu */}
            <p>Veriler yükleniyor...</p>
          </div>
        }

        {loading === false &&
          <><div className="announcementPart">
            {currentItems.slice(0, 3).map((announcement) => {
              const { month, day } = formatDate(announcement.createDate);

              return (
                <div className="card" key={announcement.id} onClick={() => handleCardClick(announcement)}>

                  {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                    announcement.ImagePath.map((imagePath, index) => (
                      <img
                        key={index}
                        src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                        className="card-img-top"
                        alt="..."
                      />
                    ))
                  ) : (
                    // Eğer ImagePath boş ise, varsayılan görseli göster
                    <img
                      src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                      className="card-img-top"
                      alt="announcement img"
                    />
                  )}
                  <div className="card-body">
                    <div className="card-content">
                      <div className="card-date">
                        <p className="card-date-month">{month.toLocaleUpperCase()}</p>
                        <p className="card-date-day">{day}</p>
                      </div>
                      <div className="card-text-content">
                        <h5 className="card-title">{announcement.title}</h5>
                        <p className="card-text">{announcement.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div><div className="announcementPart">

              {currentItems.slice(3, 6).map((announcement) => {
                const { month, day } = formatDate(announcement.createDate);

                return (
                  <div className="card" key={announcement.id} onClick={() => handleCardClick(announcement)}>

                    {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                      announcement.ImagePath.map((imagePath, index) => (
                        <img
                          key={index}
                          src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                          className="card-img-top"
                          alt="..."
                        />
                      ))
                    ) : (
                      // Eğer ImagePath boş ise, varsayılan görseli göster
                      <img
                        src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                        className="card-img-top"
                        alt="announcement img"
                      />
                    )}
                    <div className="card-body">
                      <div className="card-content">
                        <div className="card-date">
                          <p className="card-date-month">{month.toLocaleUpperCase()}</p>
                          <p className="card-date-day">{day}</p>
                        </div>
                        <div className="card-text-content">
                          <h5 className="card-title">{announcement.title}</h5>
                          <p className="card-text">{announcement.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className='pagination'>
              {currentPage > 1 && (
                <button onClick={handlePrevPage} className="paginationButton">
                  <i className="fas fa-chevron-left"></i></button>
              )}

              {/* Sayfa numaralarını göster */}
              {[...Array(totalPages).keys()].map((_, index) => {
                const pageNumber = index + 1;

                // Sadece currentPage'e göre 3 sayfa numarası göster
                if (pageNumber === currentPage || pageNumber === currentPage - 1 || pageNumber === currentPage + 1) {
                  return (
                    <div key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
                      <button className={`paginationButton ${pageNumber === currentPage ? 'active' : ''}`}>
                        {pageNumber}
                      </button>
                    </div>
                  );
                }

                return null;
              })}

              {currentPage < totalPages && (
                <button onClick={handleNextPage} className="paginationButton">
                  <i className="fas fa-chevron-right"></i>
                </button>
              )}
            </div>


          </>
        }

      </div>


      {selectedAnnouncement && (
        <div className="announcement-modal">
          <div className="announcement-modal-content">

            {selectedAnnouncement.ImagePath && selectedAnnouncement.ImagePath.length > 0 ? (
              selectedAnnouncement.ImagePath.map((imagePath, index) => (
                <img
                  key={index}
                  src={`${protocol}://${ipAdress}:${port}${imagePath}`}
                  className="announcement-modal-top-image"
                  alt="modal-image"
                />
              ))
            ) : (
              // Eğer ImagePath boş ise, varsayılan görseli göster
              <img
                src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                className="announcement-modal-top-image"
                alt="announcement img"
              />
            )}

            <div className="announcement-modal-body" >


              <p className="announcement-modal-title">{selectedAnnouncement.title}</p>

              <p className="announcement-modal-text" >{selectedAnnouncement.content}</p>


              {pdfLinks[selectedAnnouncement.id] && (
                <p className="announcement-modal-text">
                  <a
                    href={pdfLinks[selectedAnnouncement.id].url}
                    download
                    style={{ color: 'blue' }}
                  >
                    {pdfLinks[selectedAnnouncement.id].text}
                  </a>
                </p>
              )}


            </div>

            <button className="announcement-close-button" onClick={handleCloseModal}>Duyurulara Geri Dön</button>

          </div>
        </div>

      )}


    </div>



  );

}
