import React, { useState, useEffect, useRef } from 'react'
import '../styles/Exam.css';
import { Button, Card, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExamRecordService from '../services/ExamRecordService';
import PresentationFileService from '../services/PresentationFileService';
import ConfirmationModal from '../layouts/modals/ConfirmationModal';
import InfoModal from '../layouts/modals/InfoModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function Exam() {

    const userId = sessionStorage.getItem("userId");

    const location = useLocation();
    //const { questions = [] } = location.state || {};

    const navigate = useNavigate();
    const examRecordService = new ExamRecordService()
    const presentationFileService = new PresentationFileService()

    // const getInitialState = (key, defaultValue) => {
    //     const saved = sessionStorage.getItem(key);
    //     return saved ? JSON.parse(saved) : defaultValue;
    // };

    //const [timeLeft, setTimeLeft] = useState(getInitialState('timeLeft', 3600)); //3600 - 1 saat
    const [timeLeft, setTimeLeft] = useState(5400);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const [step, setStep] = useState(1);
    const [questionNumber, setQuestionNumber] = useState(1);
    const [questions, setQuestions] = useState([]);
    const questionText = questions[questionNumber - 1]?.question;

    const [answers, setAnswers] = useState({});

    const [pdf, setPdf] = useState(null);
    const [uploadPdfDate, setUploadPdfDate] = useState('');
    const fileInputRef = useRef(null);

    const [showLoadPdfButton, setShowLoadPdfButton] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState('');
    const [infoModalBody, setInfoModalBody] = useState('');

    const [showFullScreenModal, setShowFullScreenModal] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isIntentionalExit, setIsIntentionalExit] = useState(false);
    const [fullScreenCountdown, setFullScreenCountdown] = useState(30); // 30 saniye
    const [fullScreenTimerId, setFullScreenTimerId] = useState(null); // Timer ID

    const [showStartExamModal, setShowStartExamModal] = useState(false);

    const toggleFullScreen = () => {
        setIsIntentionalExit(false);

        // Tam ekran moduna geç
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { // Firefox
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
            document.documentElement.msRequestFullscreen();
        }
        setIsFullScreen(true);
    };

    const exitFullScreen = () => {
        setIsIntentionalExit(true);

        // Tam ekran modundan çıkış fonksiyonu
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
        }
        setIsFullScreen(false);
    };

    useEffect(() => {

        const handleFullScreenChange = () => {
            if (!document.fullscreenElement && !isIntentionalExit) {
                // Only show modal if the exit was not intentional
                setShowFullScreenModal(true);

                //console.log("tam ekrandan çıkamaya çalıştı!!!")
                startfullScreenCountdown(); // Geri sayımı başlat

            } else {
                setShowFullScreenModal(false); // Ensure the modal is hidden when exiting intentionally
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, [isIntentionalExit]);

    const handleFullScreenModalConfirm = () => {
        setShowFullScreenModal(false);
        toggleFullScreen();
        resetFullScreenCountdown(); // Geri sayımı sıfırla
    };

    const handleFullScreenModalCancel = () => {
        setShowFullScreenModal(false);
        setStep(3)
        resetFullScreenCountdown(); // Geri sayımı sıfırla
    };

    const startfullScreenCountdown = () => {
        setFullScreenCountdown(20); // 30 saniye
        const id = setInterval(() => {
            setFullScreenCountdown(prev => {
                if (prev < 1) {
                    //console.log("startfullScreenCountdown")
                    clearInterval(id); // Timerı temizle
                    sendEmailToAdmin(); // E-posta gönder
                    return 0; // Geri sayım 0'a düşsün
                }
                return prev - 1; // Her saniye geri say
            });
        }, 1000);
        setFullScreenTimerId(id); // Timer ID'sini sakla
    };

    const resetFullScreenCountdown = () => {
        clearInterval(fullScreenTimerId); // Timerı temizle
        setFullScreenCountdown(20); // Geri sayımı sıfırla
    };

    const sendEmailToAdmin = async () => {
        //console.log("çıkmaya çalıştı!")
        await examRecordService.sendWarningExitFullScreenMailToAdmin()
    };

    useEffect(() => {

        //console.log(userId)

        if (userId === undefined || userId === null) {
            navigate("/home")

        } else {

            presentationFileService.getPresentationByUserId(userId).then(result => {

                if (result.data.success && result.data.data.length > 0) {
                    const fileNodeResponse = result.data.data[0].fileNodeResponse;

                    if (fileNodeResponse && fileNodeResponse.name) {

                        const fileName = fileNodeResponse.name;
                        const uploadedPdfData = {
                            name: fileName
                        };
                        setPdf(uploadedPdfData);
                        setShowLoadPdfButton(false);

                        setTimeout(() => {
                            setStep(3);
                        }, 3000);  // 3000 milisaniye = 3 saniye

                    } else {
                        //console.log("Daha önce yüklenmiş bir PDF bulunamadı.");
                    }

                } else {
                    //console.log("Daha önce yüklenmiş bir PDF bulunamadı.");
                }

            }).catch(error => {
                toast.error("Kullanıcı sunum bilgisini çekerken!");
            })

        }

    }, []);

    useEffect(() => {
        const handleCopy = (e) => {
            e.preventDefault(); // Kopyalama işlemini engelle
        };

        window.addEventListener('copy', handleCopy);

        // Temizleme işlemi
        return () => {
            window.removeEventListener('copy', handleCopy);
        };
    }, []);

    useEffect(() => {

        if (timeLeft === 0) {
            toast.success("süre bitti");

            saveQuestionAnswer();

            setStep(3);
            exitFullScreen()

            return;
        }
        else if (timeLeft === 300) {
            setShowInfoModal(true)
            setInfoModalTitle('⚠️ UYARI')
            setInfoModalBody("Sınav bitimine son 5 dakikanız kalmıştır. Başarılar dileriz.")
        }

        if (step == 2) {

            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000); // Her saniyede 1 azalacak

            return () => clearInterval(timer); // Komponent unmount edilirse timer'ı temizle
        }

    }, [timeLeft, step]);

    useEffect(() => {

        // Tarih kontrol fonksiyonu
        const checkDate = () => {
            const now = new Date();

            // Başlangıç ve bitiş tarihleri
            const startDate = new Date('2024-11-20T10:00:00'); // Başlangıç tarihi
            const endDate = new Date('2024-11-20T12:00:00'); // Bitiş tarihi

            // belirtilen tarih aralaığında mı?
            if (!(now >= startDate && now <= endDate)) {


                if (!(Object.keys(answers).length === 0)) {
                    saveQuestionAnswer();

                    setShowInfoModal(true)
                    setInfoModalTitle('⚠️ UYARI')
                    setInfoModalBody("Sınav sonuçlarınız kayıt edilmiştir.")

                    setAnswers({})
                }

                if (document.hasFocus() && document.fullscreenElement) {
                    exitFullScreen()
                }

                setStep(3);

                return;
            }

        };

        checkDate();

        // Saniyede bir kontrol edecek interval ayarla
        const intervalId = setInterval(checkDate, 1000);
        // Bileşen unmount olduğunda interval'ı temizle
        return () => clearInterval(intervalId);

    }, [answers]);

    const handleAnswer = (e, index) => {
        setAnswers({ ...answers, [index]: e.target.value });
    };

    const saveQuestionAnswer = async () => {

        const currentQuestion = questions[questionNumber - 1];

        const updateDatas = {
            "id": currentQuestion.id,
            "questionResponse": answers[questionNumber] || ""
        }

        // Submit the answer for the current question
        const result = await examRecordService.updateExamRecord(currentQuestion.id, updateDatas)
        return result;
    }

    const handleNextQuestion = async () => {

        const result = await saveQuestionAnswer()

        if (result.data.success) {

            // Move to the next question
            if (questionNumber < questions.length) {
                setQuestionNumber(questionNumber + 1);
            } else {
                setStep(3);
                exitFullScreen()
            }
        }
        else {
            toast.error("Cevabınız kaydedilemedi!")
        }
    };

    const handleNextQuestionConfirmation = () => {
        setModalTitle('⚠️ UYARI');
        setModalBody('Bir sonraki soruya geçmek istiyor musunuz?');
        setShowModal(true);
    };

    const handleFinishExamConfirmation = () => {
        setModalTitle('⚠️ UYARI');
        setModalBody('Sınavı bitirmek istiyor musunuz?');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmNextQuestion = () => {
        setShowModal(false);  // Modalı kapat
        handleNextQuestion(); // Bir sonraki soruya geçiş yap
    };

    const handlePdfUpload = (e) => {
        setPdf(e.target.files[0]);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;

        setUploadPdfDate(formattedDate);
    };

    const handlePdfRemove = () => {
        setPdf(null);
        setUploadPdfDate('');

        fileInputRef.current.value = null;
    };

    const handleUploadPdfButtonClick = () => {
        fileInputRef.current.click(); // input tıklamasını simüle eder
    };

    const loadPdfHandleSubmit = async () => {

        if (pdf === null) {
            toast.error("Sunum dosyası seçtiğinizden emin olunuz!");
        } else {

            try {

                let addPresentationDatas = new FormData();
                addPresentationDatas.append("file", pdf);

                const loadPresentationResult = await presentationFileService.addPresentationFile(addPresentationDatas);

                if (loadPresentationResult.data.success) {
                    toast.success("Sunum dosyası sisteme başarıyla yüklendi.");

                    const startExamResult = await examRecordService.startExam();

                    if (startExamResult.data.success) {

                        setShowStartExamModal(false);

                        setQuestions(startExamResult.data.data);
                        setStep(2);
                        toggleFullScreen()

                    } else {
                        toast.error("Sınava iki kez katılım sağlayamazsınız!");
                        throw new Error("Sınav başlatılamadı!"); // Hata fırlat, eğer success false ise
                    }

                } else {
                    toast.success("Sunum dosyası sisteme yüklenemedi, lütfen tekrar deneyiniz!");
                    throw new Error("Sunum dosyası yüklenemedi!");
                }

            } catch (error) {
                toast.error("Sınava iki kez katılım sağlayamazsınız!");
                return;
            }

        }

    };

    const autoResize = (e) => {
        e.target.style.height = 'auto'; // Öncelikle yüksekliği sıfırla
        e.target.style.height = `${e.target.scrollHeight}px`; // İçerik yüksekliğine göre ayarla
    };

    const exitExamHandleSubmit = () => {
        navigate('/home')
    }

    const handleStartExamModalConfirm = async () => {

        await loadPdfHandleSubmit()
    };

    const handleStartExamModalCancel = () => {
        setShowStartExamModal(false);
    };

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
    };

    return (

        <div>
            <main className="exam-bg-img">

                <div className="exam-container mt-5 mb-3">

                    <div className='exam-card'>
                        <div className='exam-stepper-card'>

                            {step === 1 && (
                                <img src="/images/exam/step-1.png" alt="..." className='exam-stepper-img' />
                            )}

                            {step === 2 && (
                                <img src="/images/exam/step-2.png" alt="..." className='exam-stepper-img' />
                            )}

                            {step === 3 && (
                                <img src="/images/exam/step-3.png" alt="..." className='exam-stepper-img' />
                            )}

                        </div>
                    </div>

                    <div className='exam-card'>
                        <div className='exam-question-card'>

                            {step === 1 && (

                                <div>

                                    <div className="exam-question-header">

                                        <div className="exam-question-number-count">
                                            <FontAwesomeIcon className='exam-icon' icon={faCircle} size="xl" />
                                            <h2 className='exam-question-number'>Sunumunuzu Yükleyin</h2>
                                        </div>

                                        {/* <div className="exam-time-left">
                                            <span>
                                                Kalan Süre: {formattedHours}:{formattedMinutes}:{formattedSeconds}
                                            </span>
                                        </div> */}

                                    </div>

                                    <p className='poppins-light-text'>Bu alana yarışma için hazırladığınız sunumu yükleyin. Lütfen sunumunuzun tüm kurallara ve şartlara uygun olduğundan emin olun.
                                        Yüklemeden önce aşağıdaki hususlara dikkat edin:</p>

                                    <div className='poppins-light-text'>
                                        <ol>
                                            <li>
                                                <span>Son Kontroller:</span>
                                                <ul>
                                                    <li>Sunumunuzu yüklemeden önce tüm içeriklerin doğru ve eksiksiz olduğundan emin olun.</li>
                                                    <li>Yüklenen sunumlar tekrar düzenlenemeyecektir.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <span>Dosya Formatı:</span>
                                                <ul>
                                                    <li>Sunumlarınızı yalnızca ZIP formatında yükleyebilirsiniz.</li>
                                                    <li>Maksimum dosya boyutu: (40 MB)</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <span>Sunum Yükleme Ve Sınava Başlama:</span>
                                                <ul>
                                                    <li>Sunumunuzu yüklemek ve sınava başlamak için "Belgeni Onayla ve Sınava Başla" butonuna tıklayınız.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </div>

                                    <p>Başarılar dileriz!</p>

                                    <input
                                        type="file"
                                        accept=".zip"
                                        onChange={handlePdfUpload}
                                        ref={fileInputRef}
                                        id="fileUpload"
                                        className='exam-select-pdf-input'
                                    />

                                    {showLoadPdfButton &&
                                        <label className="exam-select-pdf" htmlFor="fileUpload">
                                            <button className="btn exam-question-btn" onClick={handleUploadPdfButtonClick}>
                                                Belge Seç
                                            </button>
                                        </label>
                                    }

                                    <Card className="mt-3 exam-select-pdf-card">
                                        <Card.Body className="d-flex align-items-center exam-select-pdf-card-body">

                                            <Badge bg="danger" className="me-2 exam-select-pdf-badge">ZIP</Badge>

                                            <div className='exam-select-pdf-card-title d-flex align-items-center flex-grow-1'>
                                                <div className="text-truncate-container flex-grow-1">
                                                    <Card.Title className="mb-0 exam-select-pdf-card-text text-truncate">
                                                        {pdf ? pdf.name : 'Dosya Yüklenmedi'}
                                                    </Card.Title>
                                                    <Card.Text className='exam-select-pdf-card-date-text'>
                                                        {pdf ? uploadPdfDate : 'Tarih Bilgisi Yok'}
                                                    </Card.Text>
                                                </div>

                                                {pdf && showLoadPdfButton && (
                                                    <Button variant="outline" size="xl" onClick={handlePdfRemove} className='exam-select-pdf-card-button'>
                                                        X
                                                    </Button>
                                                )}
                                            </div>

                                        </Card.Body>
                                    </Card>

                                    {showLoadPdfButton &&
                                        <button className="btn exam-question-btn" onClick={() => setShowStartExamModal(true)}>
                                            Belgeni Onayla ve Sınava Başla
                                        </button>
                                    }
                                    {!showLoadPdfButton &&
                                        <button className="btn exam-question-btn mt-3" onClick={exitExamHandleSubmit}>
                                            Anasayfa
                                        </button>
                                    }
                                </div>

                            )}

                            {step === 2 && (

                                <div>
                                    <div className="exam-question-header">

                                        <div className="exam-question-number-count">
                                            <FontAwesomeIcon className='exam-icon' icon={faCircle} size="xl" />
                                            <h2 className='exam-question-number'>{questionNumber}. SORU</h2>
                                            <span className="exam-question-count">({questionNumber}/{questions.length})</span>
                                        </div>

                                        <div className="exam-time-left">
                                            <span>
                                                Kalan Süre: {formattedHours}:{formattedMinutes}:{formattedSeconds}
                                            </span>
                                        </div>

                                    </div>

                                    {/* <div className='exam-question-imgs'>
                                        {questions[questionNumber - 1]?.questionImagePaths.map((imagePath, index) => (
                                            <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="question image" />
                                        ))}
                                    </div> */}

                                    {/* <p className='exam-question-text'>{questions[questionNumber - 1]?.question}</p> */}

                                    {/* <div>
                                        {questionText.includes(';') ? (
                                            // Alt maddeler varsa liste olarak göster
                                            <ul>
                                                {questionText.split(';').map((item, index) => (
                                                    index === 0 ? ( // İlk öğe için
                                                        <p key={index} className='exam-question-text poppins-bold-text'>
                                                            {item}
                                                        </p>
                                                    ) : ( // Diğer öğeler için
                                                        <li key={index} className='exam-question-text poppins-light-text'>
                                                            {item}
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        ) : (
                                            // Alt maddeler yoksa düz paragraf olarak göster
                                            <p className='exam-question-text poppins-light-text'>
                                                {questionText}
                                            </p>
                                        )}
                                    </div> */}

                                    <div className={`exam-question-container ${questions[questionNumber - 1].id === 8 ? "horizontal-layout" : "vertical-layout"}`}>
                                        <div className="exam-question-imgs">
                                            {questions[questionNumber - 1]?.questionImagePaths.map((imagePath, index) => (
                                                <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="question image" />
                                            ))}
                                        </div>

                                        <div className="exam-question-text-container">
                                            {questionText.includes(';') ? (
                                                <ul>
                                                    {questionText.split(';').map((item, index) => (
                                                        index === 0 ? (
                                                            <p key={index} className='exam-question-text poppins-bold-text'>
                                                                {item}
                                                            </p>
                                                        ) : (
                                                            <li key={index} className='exam-question-text poppins-light-text'>
                                                                {item}
                                                            </li>
                                                        )
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className='exam-question-text poppins-light-text'>
                                                    {questionText}
                                                </p>
                                            )}
                                        </div>
                                    </div>


                                    {/* <p className='poppins-regular-text'>Aşağıdaki alana cevabınızı yazınız.</p> */}
                                    <textarea
                                        type="text"
                                        placeholder="Cevabınızı yazın"
                                        value={answers[questionNumber] || ''}
                                        onChange={(e) => handleAnswer(e, questionNumber)}
                                        onInput={autoResize}
                                        onCopy={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                        className='exam-question-input'
                                    />

                                    {questionNumber < questions.length ? (
                                        <button
                                            className={`btn ${answers[questionNumber] ? "exam-question-btn-dark" : "exam-question-btn"} mt-3`}
                                            onClick={handleNextQuestionConfirmation}
                                        >
                                            Cevabı Onayla ve Sonraki Soruya Geç
                                        </button>
                                    ) : (
                                        <button className="btn exam-question-btn mt-3" onClick={handleFinishExamConfirmation}>
                                            Sınavı Tamamla
                                        </button>
                                    )}
                                </div>

                            )}

                            {step === 3 && (

                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <FontAwesomeIcon className='exam-icon' icon={faCircle} size="xl" />
                                        <h2 className='exam-question-number'>Sınavı Tamamladınız!!</h2>
                                    </div>

                                    <div className='exam-finish-text'>
                                        <p className='poppins-bold-text'>Tüm soruları yanıtladınız ve sürecin ilk aşamasını tamamladınız.</p>
                                        <p className='poppins-light-text'>
                                            Sunumunuzun yüklenmesiyle ve sınav etabını tamamlamanız ile birlikte başvurunuz alınmıştır.
                                            Şu andan itibaren, değerlendirme süreci başlamıştır. Sonuçlar için duyuruları takip etmeyi unutma.
                                        </p>
                                        <p className='poppins-bold-text'>Yarışmamıza katıldığınız için teşekkür eder, başarılar dileriz!</p>
                                    </div>

                                    <button className="btn exam-question-btn mt-3" onClick={exitExamHandleSubmit}>
                                        Anasayfa
                                    </button>
                                </div>

                            )}
                        </div>
                    </div>

                </div>

                {
                    showModal && <ConfirmationModal
                        show={showModal}
                        handleClose={handleCloseModal}
                        handleConfirm={handleConfirmNextQuestion}
                        title={modalTitle}
                        body={modalBody}
                    />
                }

                {
                    showInfoModal && <InfoModal
                        show={showInfoModal}
                        handleClose={handleCloseInfoModal}
                        title={infoModalTitle}
                        body={infoModalBody}
                    />
                }

                {
                    showFullScreenModal &&
                    <div className="my-modal">
                        <div className="my-modal-content">
                            <button className="my-modal-close-button" onClick={handleFullScreenModalCancel}>X</button>
                            <div className="my-modal-body">
                                <p>{'⚠️ UYARI'}</p>
                                <p>{'Sınav esnasında tam ekran modundan çıkış yapamazsınız. Tam ekran modundan çıkış yapmayı onaylıyorsanız sınavınız sonlanacaktır!'}</p>
                                <p>{'Size verilen süre içerisinde sınav ekranına geri dönüş yapmazsanız sisteme kayıt oluşturulacak ve sınavınız ona göre değerlendirilecektir.'}</p>
                                <p>{`Kalan Süre: ${fullScreenCountdown} saniye`}</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn my-modal-confirm-button" onClick={handleFullScreenModalCancel}>Sınavı Sonlandır</button>
                                <button className="btn my-modal-refuse-button" onClick={handleFullScreenModalConfirm}>Sınava Devam Et</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    showStartExamModal &&
                    <div className="my-modal">
                        <div className="my-modal-content">
                            <button className="my-modal-close-button" onClick={handleStartExamModalCancel}>X</button>
                            <div className="my-modal-body">
                                <p>{'⚠️ UYARI'}</p>
                                <p>{'Sınava başlamadan önce internet bağlantınızın stabil olduğundan emin olunuz!'}</p>
                                <p>{'Sınav esnasında kesinlikle sayfayı yenilememeniz gerekir. Sayfayı yenilemeniz halinde sınavınız son bulacaktır.'}</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn my-modal-refuse-button" onClick={handleStartExamModalConfirm}>Sınava Başla</button>
                            </div>
                        </div>
                    </div>
                }

            </main >
        </div >
    )
}

