import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/SignInPage.css';
import { Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import UserService from '../services/UsersService';
import { toast } from "react-toastify";
import { jwtDecode } from 'jwt-decode';

export default function SignInPage() {

    const navigate = useNavigate();
    const userService = new UserService()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};

        // Email validasyonu (basit bir regex kullanımı)
        if (!email) {
            newErrors.email = 'Eposta gereklidir';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Geçersiz eposta formatı';
        }

        // Şifre validasyonu (örneğin en az 6 karakter)
        if (!password) {
            newErrors.password = 'Şifre gereklidir';
        } else if (password.length < 8) {
            newErrors.password = 'Şifre en az 8 karakter olmalıdır';
        }

        return newErrors;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = () => {

        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Eğer hata yoksa, login işlemi yapılabilir
            setErrors({})

            const formData = {
                "usernameOrEmail": email,
                "password": password
            }

            userService.login(formData).then(result => {

                sessionStorage.setItem('token', result.data.accessToken);

                setEmail("")
                setPassword("")

                const decodedUserNameFromToken = jwtDecode(result.data.accessToken);

                userService.getUserWithUsernameOrEmail(decodedUserNameFromToken.sub).then(response => {

                    console.log(response)

                    if (response.data.success === true) {

                        if (response.data.data.status === "ACTIVE") {
                            // sessiona kaydet
                            sessionStorage.setItem('role', response.data.data.roles[0].name);
                            sessionStorage.setItem('firstName', response.data.data.firstName);
                            sessionStorage.setItem('lastName', response.data.data.lastName);
                            sessionStorage.setItem('userId', response.data.data.id);
                            
                            toast.success("Giriş başarılı.");

                            navigate("/home/user-home")
                        }else{
                            toast.error("Sisteme erişim yetkiniz yok!")
                        }


                    }
                })

            }).catch(error => {

                toast.error(error.response.data.data.message);
                console.error(error);
            })
        }
    };


    const termOfUseClick = () => {
        window.open('/pdf-files/Hayrettin Atmaca Vakıfı_İnternet Sitesi_Kullanım Koşulları.pdf', '_blank')
    };

    const privacyPolicyClick = () => {
        window.open('/pdf-files/Hayrettin Atmaca Vakıfı_İnternet Sitesi_Bilgi Güvenliği.pdf', '_blank')
    };

    const signUpButtonClick = () => {
        navigate("/home/signUp")
    };

    return (

        <div className="signIn-main">

            <div className='signIn-container'>

                <div className="signIn-section">

                    <div className="signIn-card p-4">
                        <h2 className="signIn-h2 text-center mb-4">Giriş Yap</h2>

                        <form>

                            <div className="mb-3">
                                <label htmlFor="email" className="signIn-form-label">Eposta</label>
                                <input
                                    type="email"
                                    id="email"
                                    className="form-control signIn-form-input"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    value={email}
                                />
                                {errors.email && <small className="text-danger">{errors.email}</small>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="signIn-form-label">Şifre</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    className="form-control signIn-form-input"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    value={password}
                                />
                                <i
                                    className={`signIn-hide-password-icon fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                                {errors.password && <small className="text-danger">{errors.password}</small>}
                            </div>

                            <p className="signIn-text-info">
                                Devam ederek,
                                <span className="signIn-underline" onClick={termOfUseClick}>Kullanım Koşulları</span>
                                ve
                                <span className="signIn-underline" onClick={privacyPolicyClick}>Bilgi Güvenliği Politikası</span>
                                <p className='signIn-p'>kabul etmiş olursunuz.</p>
                            </p>
                            <button type="button" className="signIn-btn w-100 mb-3" onClick={handleLogin}>
                                Giriş Yap
                            </button>
                            <a href="/home/forgot-password" className="d-block text-center mb-3">Şifremi Unuttum</a>

                        </form>

                    </div>

                </div>

                <div className="signIn-signUp-section">
                    <div className="text-center"><p className='signIn-p'>ya da</p></div>
                    <button type="button" className="signIn-sign-up-btn btn-outline-secondary"
                        onClick={signUpButtonClick}>
                        Kayıt Ol
                    </button>
                </div>

            </div>

        </div>


    )
}
