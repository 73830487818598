import React, { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../services/UsersService';
import CityService from '../services/CityService'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import '../styles/SignUp.css';
import InfoModal from '../layouts/modals/InfoModal'


export default function SignUpPage() {

  const navigate = useNavigate();
  const userService = new UserService();
  const cityService = new CityService();

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [isCityValid, setIsCityValid] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // Belirtilen tarih ve saati oluşturun
     const targetDate = new Date("2024-11-19T23:59:00"); // 19 Kasım 2024, saat 23:59   


    const checkDisableStatus = () => {
      const currentDate = new Date();
      // Eğer geçerli tarih hedef tarihten büyükse, butonu disable yap
      if (currentDate >= targetDate) {
        setIsDisabled(true);

        setModalTitle("📢 BİLGİ !")
        setModalBody("Başvuru süreci sona ermiştir. İlginiz için teşekkür ederiz. Bir sonraki yarışmada görüşmek üzere 😊")
        setShowModal(true);

      } else {
        setIsDisabled(false);
      }
    };

    // İlk kontrolü hemen yap
    checkDisableStatus();

    // Her dakika kontrol etmek için interval oluştur
    const intervalId = setInterval(checkDisableStatus, 60000);

    // Bileşen kaldırıldığında interval'i temizle
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {

    cityService.getAllCity().then(result => {

      if (result.data.success) {

        // Tüm veriyi set et
        setCities(result.data.data);
      }

    }).catch(error => {
      // toast.error(error.response.data.data.message);
      const fullMessage = error.response.data.data.message;
      const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
      const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
      toast.error(message);
    })


  }, []);

  // duyuru modalı kapat
  const handleCloseModal = () => {
    setShowModal(false);
    setModalTitle("");
    setModalBody("")
    //navigate("/home")
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [identityNo, setIdentityNo] = useState("");
  const [email, setEmail] = useState("");
  const [institutionCode, setInstitutionCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");



  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isBirthDateValid, setIsBirthDateValid] = useState(true);
  const [isIdentityNo, setIsIdentityNo] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isInstitutionCodeValid, setIsInstitutionCodeValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isGuideAccepted, setIsGuideAccepted] = useState(false);

  const isValidTc = (tc) => {
    // TC numarası 11 haneli olmalı ve ilk karakter 0 olamaz
    if (!/^[1-9][0-9]{10}$/.test(tc)) return false;

    let sumOdd = 0;  // 1, 3, 5, 7, 9. basamakların toplamı
    let sumEven = 0; // 2, 4, 6, 8. basamakların toplamı
    let sumFirst10 = 0;  // İlk 10 basamağın toplamı

    // Basamakları dolaşarak toplamları hesapla
    for (let i = 0; i < 9; i++) {
      const digit = parseInt(tc[i]);
      sumFirst10 += digit;
      if (i % 2 === 0) {
        sumOdd += digit;  // Tek basamaklar (1, 3, 5...)
      } else {
        sumEven += digit; // Çift basamaklar (2, 4, 6...)
      }
    }

    const digit10 = ((sumOdd * 7) - sumEven) % 10;  // 10. hane
    sumFirst10 += parseInt(tc[9]);  // 10. basamağı da ilk 10 toplamına ekle
    const digit11 = sumFirst10 % 10;  // 11. hane

    // 10. ve 11. basamaklar kontrol ediliyor
    return digit10 === parseInt(tc[9]) && digit11 === parseInt(tc[10]);
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    // Validate first name
    //     a-zA-Z: Tüm İngilizce harfler (büyük ve küçük).
    // ığüşöçİĞÜŞÖÇ: Türkçe karakterler (küçük ı da dahil).
    // \s: Boşluk karakteri, isimde birden fazla kelime olabilir (örneğin "Ahmet Can").
    if (!/^[a-zA-ZğüşöçİıĞÜŞÖÇ\s]+$/.test(firstName)) {

      setIsFirstNameValid(false);
      return;

    } else {
      setIsFirstNameValid(true);
    }

    // Validate last name
    if (!/^[a-zA-ZğüşöçİıĞÜŞÖÇ\s]+$/.test(lastName)) {

      setIsLastNameValid(false);
      return;

    } else {
      setIsLastNameValid(true);
    }

    if (!/^\d{4}-\d{2}-\d{2}$/.test(birthdate)) {

      setIsBirthDateValid(false);
      return;

    } else {

      const birthYear = new Date(birthdate).getFullYear();
      if (birthYear >= 2004) {
        setIsBirthDateValid(false);
        toast.warn("Bu sayfa üzerinden yalnızca danışman öğretmen kaydı yapılır! Öğrenciler ,Takım Kayıt sayfası üzerinden kayıt edilmelidir. ");
        return;
      }

      setIsBirthDateValid(true);
    }

    // Validate terms acceptance
    if (!isValidTc(identityNo)) {

      setIsIdentityNo(false)
      return;

    } else {
      setIsIdentityNo(true)
    }

    // Validate city name
    if (!selectedCity) {

      setIsCityValid(false);
      return;

    } else {
      setIsCityValid(true);
    }

    // Validate email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {

      setIsEmailValid(false);
      return;

    } else {
      setIsEmailValid(true);
    }

    // Validate institution code
    if (!/^(?!0)\d+$/.test(institutionCode.trim())) {

      setIsInstitutionCodeValid(false);
      return;

    } else {
      setIsInstitutionCodeValid(true);
    }

    // Validate password
    // (?=.*[A-Za-zığüşöçİĞÜŞÖÇ]): Şifrede en az bir harf bulunmalı, Türkçe karakterler de dahil.
    // (?=.*\d): Şifrede en az bir rakam bulunmalı.
    // [A-Za-zığüşöçİĞÜŞÖÇ\d]{8,}: En az 8 karakterden oluşmalı, harfler (Türkçe dahil) ve sayılar kullanılabilir.
    if (!/^(?=.*[A-Za-zığüşöçİĞÜŞÖÇ])(?=.*\d)[A-Za-zığüşöçİĞÜŞÖÇ\d]{8,}$/.test(password)) {

      setIsPasswordValid(false);
      return;

    } else {
      setIsPasswordValid(true);
    }

    // Validate password confirmation
    if (password !== passwordConfirmation) {

      setIsPasswordConfirmationValid(false);
      return;

    } else {
      setIsPasswordConfirmationValid(true);
    }



    // Validate terms acceptance
    if (!isGuideAccepted) {

      toast.warn("Uygulama klavuzunu ve yarışma kurallarını kabul etmelisiniz.")
      return;
    }

    // Validate terms acceptance
    if (!isTermsAccepted) {

      toast.warn('KVKK ve aydınlatma metnini kabul etmelisiniz.')

      return;
    }



    // Submit if all fields are valid
    if (isFirstNameValid && isLastNameValid &&
      isBirthDateValid && isValidTc(identityNo) && isEmailValid && isCityValid
      && isInstitutionCodeValid && isPasswordValid && isPasswordConfirmationValid
      && isTermsAccepted && isGuideAccepted) {

      let formData = new FormData();
      formData.append('firstName', firstName);
      formData.append("lastName", lastName);
      formData.append("birthdate", birthdate);
      formData.append("identityNo", identityNo);
      formData.append('institutionCode', institutionCode);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("role", "ROLE_USER");
      formData.append("cityName", selectedCity);



      userService.addUser(formData).then(result => {

        console.log(result)

        if (result.data.success === true) {

          toast.success("Kaydınız başarıyla oluşturuldu.");


          // 2 saniye sonra formu temizle
          setTimeout(() => {

            setIsGuideAccepted(false)
            setFirstName("");
            setLastName("");
            setBirthdate("");
            setIdentityNo("");
            setEmail("");
            setInstitutionCode("");
            setPassword("");
            setPasswordConfirmation("");
            setIsTermsAccepted(false)


            toast.warning("Takımınızı kaydetmek üzere yönlendiriliyorsunuz...");

            localStorage.setItem("savedUserId", result.data.data.id);
            navigate('/home/teamRegister');


          }, 2000);

        }
      }).catch(error => {

        toast.error(error.response.data.data.message);

      })


    }
  };


  return (
    <div className='signUp-container'>


      <div className='my-container-signup'>


        <div className='left-text-div' >
          <p className='txt-kayit'>Kayıt Ol</p>
          <p className='txt-kayit-dec'>Yarışmaya katılmak için danışman kaydı yapmak zorunludur. Danışman kaydınızı tamamladıktan sonra, Takım Kayıt sayfası üzerinden öğrenciler takıma eklenmelidir.Sadece danışman hesabı üzerinden erişim yapılmalıdır.</p>
          <p className='txt-kayit-dec-uyari'>Takım kayıt sayfası üzerinden takımını mutlaka oluştur, aksi takdirde kayıt işlemin tamamlanmış sayılmayacak.</p>
          <p className='txt-kayit-dec' >Bilgileri doğru girdiğinden emin ol.</p>
        </div>



        <div className='right-container-signup'>
          <form className="needs-validation" noValidate >

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="validationFirstName" className="form-label">Danışman Ad</label>
              <input
                type="text"
                className={`custom-input form-control ${!isFirstNameValid ? 'is-invalid' : ''}`}
                id="validationFirstName"
                required
                pattern="[a-zA-ZığüşöçİĞÜŞÖÇ\s]+"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <div className="invalid-feedback">
                Lütfen geçerli bir ad giriniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="validationLastName" className="form-label" >Danışman Soyad</label>
              <input
                type="text"
                className={`custom-input  form-control ${!isLastNameValid ? 'is-invalid' : ''}`}
                id="validationLastName"
                required
                pattern="[a-zA-ZığüşöçİĞÜŞÖÇ\s]+"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              <div className="invalid-feedback">
                Lütfen geçerli bir soyad giriniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="validationBirthDate" className="form-label" >Doğum Tarihi</label>
              <input
                type="date"
                className={`custom-input form-control ${!isBirthDateValid ? 'is-invalid' : ''}`}
                id="validationBirthDate"
                required
                onChange={(e) => setBirthdate(e.target.value)}
                value={birthdate}
              />
              <div className="invalid-feedback">
                Lütfen geçerli bir doğum tarihi giriniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="validationIdentityNo" className="form-label">Danışman TC Kimlik No</label>
              <input
                type="number"
                id="validationIdentityNo"
                className={`custom-input form-control ${isIdentityNo ? '' : 'is-invalid'}`}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 11) {
                    setIdentityNo(value);
                  }
                }}
                value={identityNo}
              />
              <div className="invalid-feedback">
                TC Kimlik Numaranızın doğruluğundan emin olunuz.!
              </div>
            </div>


            <div className="col-md-12 form-group mt-4">
              <label htmlFor="email" className="form-label">Danışman Email</label>
              <input
                type="email"
                id="email"
                className={`custom-input  form-control ${isEmailValid ? '' : 'is-invalid'}`}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <div className="invalid-feedback">
                Geçerli bir email adresi giriniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="city" className="form-label">Şehir</label>
              <select
                id="city"
                className={`custom-input form-control ${isCityValid ? '' : 'is-invalid'}`}
                onChange={(e) => setSelectedCity(e.target.value)}
                value={selectedCity}
              >
                <option value="">Şehir seçiniz</option>
                {cities
                  .filter((city) => city.name !== "İSTANBUL")
                  .map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
              </select>
              <div className="invalid-feedback">
                Geçerli bir şehir seçiniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">
              <label htmlFor="institutionCode" className="form-label">Kurum Kodu</label>
              <input
                type="number"
                id="institutionCode"
                className={`custom-input form-control ${isInstitutionCodeValid ? '' : 'is-invalid'}`}
                onChange={(e) => setInstitutionCode(e.target.value)}
                value={institutionCode}
              />
              <div className="invalid-feedback">
                Geçerli bir kurum kodu giriniz!
              </div>
            </div>

            <div className="col-md-12 form-group mt-4">

              <label htmlFor="password" className="form-label">Danışman Şifre</label>

              <div className="d-flex align-items-center position-relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className={`custom-input form-control ${isPasswordValid ? '' : 'is-invalid'}`}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />

                <i
                  className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ right: '10px', cursor: 'pointer' }}
                />


              </div>

              {/* Hata mesajını burada, input'tan sonra konumlandırıyoruz */}
              <div className={`invalid-feedback ${!isPasswordValid && password ? 'd-block' : 'd-none'}`}>
                Şifre en az 8 karakter uzunluğunda, harf ve rakam içermelidir. <br />
                Sadece harfler ve rakamlar içermelidir. <br />
                Noktalama işaretleri, boşluk veya özel karakterler kabul edilmez.
              </div>

            </div>



            <div className="col-md-12 form-group mt-4">
              <label htmlFor="passwordConfirmation" className="form-label" >Şifre Tekrarı</label>

              <div className="d-flex align-items-center position-relative">

                <input
                  type={showPasswordConfirm ? 'text' : 'password'}
                  id="passwordConfirmation"
                  className={`custom-input  form-control ${isPasswordConfirmationValid ? '' : 'is-invalid'}`}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  value={passwordConfirmation}
                />
                <i
                  className={`fas ${showPasswordConfirm ? 'fa-eye-slash' : 'fa-eye'} position-absolute`}
                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  style={{ right: '10px', cursor: 'pointer' }}
                />
              </div>


              <div className={`invalid-feedback ${!isPasswordConfirmationValid && passwordConfirmation ? 'd-block' : 'd-none'}`}>
                Şifreler eşleşmiyor.
              </div>


            </div>

            <div className="form-group form-check" style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px", justifyContent: "flex-start" }}>
              <input
                type="checkbox"
                className="form-check-input"
                id="termsFirst"
                onChange={(e) => setIsGuideAccepted(e.target.checked)}
                required
              />
              <label className="form-check-label" htmlFor="termsFirst">

                <span className="form-check-box-txt1" onClick={() => window.open('/pdf-files/Sunny Teknoloji Robo-Rally (2024-2025).pdf', '_blank')}> Uygulama Kılavuzunu ve Yarışma kurallarını</span>
                <span className="form-check-box-txt2"> kabul ediyorum.</span>
              </label>
            </div>

            <div className="form-group form-check" style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-start" }}>
              <input
                type="checkbox"
                className="form-check-input"
                id="terms"
                checked={isTermsAccepted}
                onChange={(e) => setIsTermsAccepted(e.target.checked)}
                required
              />
              <label className="form-check-label" htmlFor="terms">
                <span className="form-check-box-txt1" onClick={() => window.open('/pdf-files/Sunny Teknoloji Robo-Rally (2024-2025).pdf', '_blank')} > KVKK ve aydınlatma metnini  </span>
                <span className="form-check-box-txt2"> okudum ve kabul ediyorum. </span>
              </label>
            </div>


            <button
              type="button"
              className="signUp-btn"
              disabled={isDisabled}
              onClick={handleSubmit}>
              Devam et
            </button>


          </form>

        </div>

      </div >


      {showModal && <InfoModal
        show={showModal}
        handleClose={handleCloseModal}
        title={modalTitle}
        body={modalBody}
      />}



    </div >

  )
}


