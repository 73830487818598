import React, { useState, useEffect } from 'react'
import '../styles/Homepage.css';
import '../styles/Modal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import AnnouncementService from '../services/AnnouncementService';
import CityService from '../services/CityService';
import HostAndPortInfo from "../enums/EnHostandPortInfo";


const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function Homepage() {

  const [activeButton, setActiveButton] = useState('register');
  const navigate = useNavigate();

  const announcementService = new AnnouncementService();
  const cityService = new CityService();

  const [announcements, setAnnouncements] = useState([]);
  const [cities, setCities] = useState([]);

  const userId = sessionStorage.getItem("userId");

  const bannerText = [
    'Bu Teknoloji Senin İçin',
    'Bu Teknoloji Senin İçin',
    'Bu Teknoloji Senin İçin',
    'Bu Teknoloji Senin İçin',
    'Bu Teknoloji Senin İçin',
    'Bu Teknoloji Senin İçin',
  ];

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setModalTitle("Ağrı’nın Zrvesnde Teknoloj Yarışması RoboRally 2025")
    setModalBody(`
    
    `);


    setShowModal(true);
  };

  const handleSignUpClick = () => {
    setActiveButton('register');
    navigate('/home/signUp');
  };

  const handleSignInClick = () => {
    setActiveButton('login');
    navigate('/home/signIn');
  };
  
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    announcementService.getLastThreeAnnouncements().then(result => {

      if (result.data.success) {

        setAnnouncements(result.data.data);
      }

    }).catch(error => {
      // toast.error(error.response.data.data.message);
    })

    cityService.getAllCityWithUserCount().then(result => {

      if (result.data.success) {

        //console.log(result.data.data)
        setCities(result.data.data);
      }

    }).catch(error => {
      // toast.error(error.response.data.data.message);
    })

    //handleOpenModal()

  }, []);

  const formatDate = (dateString) => {
    const monthNames = { 1: "Oca", 2: "Şub", 3: "Mar", 4: "Nis", 5: "May", 6: "Haz", 7: "Tem", 8: "Ağu", 9: "Eyl", 10: "Eki", 11: "Kas", 12: "Ara" };

    const [year, month, dayWithTime] = dateString.split("-");
    const [day] = dayWithTime.split("T"); // Saat bilgisini ayırmak için

    // Ayı sayısal değere çevir ve monthNames içerisinden ayın adını al
    const monthName = monthNames[parseInt(month, 10)];


    return { month: monthName, day };
  };

  return (
    <div className="home-app">

      <main className="home-main">

        <div className="home-main-content-first">
          <img src="./images/homepage/sunny-text.png" class="home-main-content-first-img" alt="..." />
        </div>

        <div className="home-main-content">

          <h1 >Hayallerini <span className="home-highlight">kodla</span>, <br></br> Robotunu Yarıştır.</h1>
          <p>2025 için geri sayım başladı! Daha fazla il, daha fazla rakip, daha <br></br> büyük bir mücadele seni bekliyor. Şimdi katıl, zirveye tırman!</p>

          {(userId === null || userId === "" || userId === undefined) &&

            <div className="home-buttons">
              <button
                className="home-btn home-register"
                style={{
                  background: activeButton === 'register' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent',
                  color: activeButton === 'register' ? 'white' : 'black',
                  // boxShadow: activeButton === 'register' ? '4px -1px 3px 2px rgba(30, 80, 182, 1)' : '',
                }}
                onClick={handleSignUpClick}
              >
                Kayıt Ol
                <FontAwesomeIcon className="home-register-icon" icon={faArrowUpRightFromSquare} />
              </button>
              <button
                className="home-btn home-login"
                style={{
                  background: activeButton === 'login' ? 'linear-gradient(to right, darkblue, #1bb2ef)' : 'transparent',
                  color: activeButton === 'login' ? 'white' : 'black',
                  // boxShadow: activeButton === 'login' ? '4px -1px 3px 2px rgba(30, 80, 182, 1)' : '',
                }}
                onClick={handleSignInClick}
              >
                Giriş Yap
              </button>
            </div>

          }

        </div>

      </main>

      <section className="home-track-image">
      </section>

      <div className='city-scroller-header'>
        İLLERE GÖRE BAŞVURAN SAYISI
      </div>
      <div className="city-scroller-wrapper">
        <div className="city-scroller-reverse">
          <div className="city-list-reverse">
            {[...bannerText, ...bannerText].map((text, index) => (
              <div className="city-item" key={index}>
                {text}  <img className='city-item-img' src="/images/homepage/star.png" alt="star" />
              </div>
            ))}
          </div>
        </div>

        <div className="city-scroller">
          <div className="city-list">
            {[...cities]
              .filter((city) => city.city !== "İSTANBUL")
              .map((city, index) => (
                <div className="city-item" key={index}>
                  {city.city} {city.userCount}
                  <img className='city-item-img' src="/images/homepage/star.png" alt="star" />
                </div>
              ))}
          </div>
        </div>
      </div>


      <h2 className="home-announcements-header" onClick={() => navigate("/home/announcement")}>Duyurular</h2>
      <section className="home-announcements">
        <div className="home-announcement-cards" onClick={() => navigate("/home/announcement")}>

          {announcements.slice(0, 3).map((announcement) => {
            const { month, day } = formatDate(announcement.createDate);

            return (
              <div className="home-card" key={announcement.id}>

                {announcement.ImagePath && announcement.ImagePath.length > 0 ? (
                  announcement.ImagePath.map((imagePath, index) => (
                    <img
                      key={index}
                      src={imagePath ? `${protocol}://${ipAdress}:${port}${imagePath}` : `${process.env.PUBLIC_URL}/images/announcement.png`}
                      className="home-card-img-top"
                      alt="..."
                    />
                  ))
                ) : (
                  // Eğer ImagePath boş ise, varsayılan görseli göster
                  <img
                    src={`${process.env.PUBLIC_URL}/images/announcement.png`}
                    className="home-card-img-top"
                    alt="announcement img"
                  />
                )}

                <div className=" home-card-body">
                  <div className="home-card-content">
                    <div className="home-card-date">
                      <p className="home-card-date-month">{month.toLocaleUpperCase()}</p>
                      <p className="home-card-date-day">{day}</p>
                    </div>
                    <div className="home-card-text-content">
                      <h5 className="home-card-title">{announcement.title}</h5>
                      <p className="home-card-text">{announcement.content}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        </div>
      </section>

      {showModal &&
        <div className="my-modal" style={{    paddingTop: width <= 768 ? '50rem' : '25rem',
          paddingBottom: width <= 768 ? '50rem' : '0rem',
          overflow: 'auto',
          height: '100%' }}>
          <div className="my-modal-content">
            <button className="my-modal-close-button" onClick={handleCloseModal}>X</button>
            <div className="my-modal-body">
              <p style={{ fontSize: width <= 768 ? '1.1rem' : '', marginTop: width <= 768 ? '1rem' : '', }}>
                Sayın Yarışmacılar,</p>

              <p style={{ fontSize: width <= 768 ? '1.1rem' : '1.25rem', }}>
                20 Kasım 2024 tarihinde, Ağrı’nın zirvesinde Teknoloji Yarışması RoboRally 2025 için ön eleme online sınavı ile büyük yarışa ilk adım atılacak! 
                Bu sınav, yarışmaya katılabilmek için en önemli adımdır. Kolları sıvayın, yarış pistine çıkmaya hazır olun! 🏁</p>

              <h4>Sınav Detayları:</h4>
              <ul style={{ fontSize: '1.2rem' }}>
                <li>📅 Tarih: 20 Kasım 2024</li>
                <li>⏰ Sınav Saat Aralığı: 10:00 - 12:00 (Bu saatler arasında sınav aktif olacaktır!)</li>
                <li>⌛ Sınav Süresi: 1,5 saat (Sunumunuzu yüklediğinizde sınavınız başlayacaktır!)</li>
              </ul>

              <h4>Katılım Adımları:</h4>
              <ol style={{ fontSize: '1.2rem' }}>
                <li>Başvuru yaptığınız hesabınıza giriş yapın. 🚗💨</li>
                <li>Belirtilen saatler (10:00 - 12:00) arasında sınav aktif olacaktır. Sunumunuzu yükleyin. Sunumunuzu yükledikten sonra sınavınız başlayacaktır! 🏎️</li>
                <li>Sınav başladıktan sonra, süreyi etkili kullanarak sınavınızı tamamlayın. 🕘</li>
              </ol>

              <h4>Önemli Hatırlatmalar:</h4>
              <ul style={{ fontSize: '1.2rem' }}>
                <li>🕰 Sınav sadece 10:00 - 12:00 arasında aktif olacaktır. 12:01'de sistem kapanacaktır! Bu yüzden belirtilen saatler içinde katılımınızı gerçekleştirdiğinizden emin olun.</li>
                <li>⌛ Sınav süresi 1,5 saat olacak. Zamanınızı verimli kullanarak sınavınızı tamamlayın.</li>
                <li>📰 Detaylı bilgi ve güncellemeler için duyuruları takip etmeyi unutmayın! Yarışmanın her anı önemli!</li>
              </ul>

              <p>🚀 Bu fırsatı kaçırmayın! Robotunuzu hızla yarışa sokarak bir adım daha ileriye taşıyın! 🏁</p>

              <p>Başarılar dileriz!🚗💨</p>
            </div>
          </div>
        </div>
      }

    </div>

  )
}
