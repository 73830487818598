import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Footer.css';
import { useNavigate } from "react-router-dom";

function Footer() {

    const navigate = useNavigate();

    const communicationClick = () => {

        navigate("/home/contact")
    };

    return (

        <div>
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-row">

                        <div className="footer-logo">
                            <img src="/images/footer/meb-logo.png" alt="Meb Logo" className="meb-logo" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/footer/agri-valilik-logo.png" alt="Agri Logo" className="agri-logo" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/footer/atmaca-logo.png" alt="Atmaca Logo" className="atm-logo" />
                        </div>
                        <div className="footer-logo">
                            <img src="/images/footer/sunny-logo.png" alt="Sunny Logo" className="sunny-logo" />
                        </div>

                        <div className="footer-menu">
                            <ul>
                                <li><h5 className='poppins-bold'>KURUMSAL</h5></li>
                                <li className='poppins-extra-light' onClick={() => window.open('/pdf-files/Hayrettin Atmaca Vakfı_Yarışma_İnternet Sitesi KVKK_03.10.2024.pdf', '_blank')}>KVKK Metni</li>
                                <li className='poppins-extra-light' onClick={() => window.open('/pdf-files/Sunny Teknoloji Robo-Rally (2024-2025).pdf', '_blank')}>Uygulama Kılavuzu</li>
                                <li className='poppins-extra-light' onClick={communicationClick}>İletişim</li>
                            </ul>
                        </div>

                        <div className="footer-contact">
                            <p className='poppins-semi-bold'>destek@sunnyteknoloji.com.tr</p>
                            <div className="social-icons">
                                <a href="https://www.facebook.com/sunny.turkiye/" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/footer/facebook.png" alt="Facebook" />
                                </a>
                                <a href="https://x.com/sunny_turkiye" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/footer/x-logo.png" alt="X" />
                                </a>
                                <a href="https://www.linkedin.com/company/sunnyelektronik/mycompany/" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/footer/linkedin.png" alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <p>
                            <span className="poppins-bold">Tüm Hakları Saklıdır &copy;</span>
                            <span className="poppins-extra-light"> Sunny Robot Yarışması Web Sitesi</span>
                        </p>
                        <div className="version-text">V - 1.0.13</div>
                    </div>

                </div>
            </footer>
        </div>


    );

}

export default Footer;
